import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, CircularProgress, Avatar, Container, Grid, Paper } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../AuthContext'; // Certifique-se de que o caminho está correto
import { useNavigate } from 'react-router-dom'; // Certifique-se de importar useNavigate

function Conta() {
  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState('');
  const { isAuthenticated, login, logout, user } = useAuth();
  const navigate = useNavigate();

  const handleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoading(true);
      fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          login(data);
          localStorage.setItem('accessToken', tokenResponse.access_token); // Armazena o token no localStorage
          setLoading(false);

          const redirectTo = localStorage.getItem('redirectAfterLogin');
          if (redirectTo) {
            localStorage.removeItem('redirectAfterLogin');
            navigate(redirectTo);
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar informações do usuário:', error);
          setLoading(false);
        });
    },
    onError: (error) => {
      console.log('Falha no login:', error);
      setLoading(false);
    },
  });

  const handleLogout = () => {
    logout();
    localStorage.removeItem('accessToken'); // Remove o token do localStorage
    console.log('Logout Successful');
  };

  const handleApiTest = async () => {
    const token = localStorage.getItem('accessToken'); // Certifique-se de que o token está sendo armazenado corretamente

    console.log('Iniciando requisição à API com token:', token);

    try {
      const response = await fetch('http://localhost:5000/api/protected', {
        method: 'GET',
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.text();
      setApiResponse(data);
      console.log('Resposta da API:', data);
    } catch (error) {
      console.error('Erro ao acessar a API:', error);
      setApiResponse('Não foi possível acessar a API. Verifique se você está autenticado.');
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [isAuthenticated]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <AppBar position="static" color="inherit" style={{ width: '100%' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Minha Conta
          </Typography>
          {isAuthenticated && (
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{ marginTop: '20px' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          {isAuthenticated ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                {user.picture && (
                  <Avatar src={user.picture} alt="User profile" style={{ margin: 'auto', width: '100px', height: '100px' }} />
                )}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h6">{user.name}</Typography>
                <Typography variant="body1">{user.email}</Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" color="secondary" onClick={handleLogout}>
                  Logout
                </Button>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={handleApiTest}>
                  Testar API
                </Button>
                <Typography variant="body1" style={{ marginTop: '20px' }}>
                  {apiResponse}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Typography variant="body1" gutterBottom>
                Você não está logado. Faça login para acessar suas informações.
              </Typography>
              <div style={{ marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleLogin} style={{ marginRight: '10px' }}>
                  Login com Google
                </Button>
                <Button variant="contained" color="secondary" onClick={handleApiTest}>
                  Testar API
                </Button>
              </div>
              <Typography variant="body1" style={{ marginTop: '20px' }}>
                {apiResponse}
              </Typography>
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
}

export default Conta;
