import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css'; // Ensure Leaflet CSS is included

const MapWithRoute = ({ userCoords, destinationCoords }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const routeLayerRef = useRef(null); // Ref for storing the route layer

  useEffect(() => {
    // Function to initialize or update the map
    const initializeMap = () => {
      if (!userCoords || !destinationCoords) {
        console.error('userCoords or destinationCoords is missing');
        return;
      }

      if (mapRef.current) {
        // Initialize map instance if not already done
        if (!mapInstanceRef.current) {
          mapInstanceRef.current = L.map(mapRef.current, {
            center: [userCoords.lat, userCoords.lng],
            zoom: 13,
          });

          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }).addTo(mapInstanceRef.current);
        } else {
          // Update map view if map instance already exists
          mapInstanceRef.current.setView([userCoords.lat, userCoords.lng], 13);
        }

        // Remove existing route layer if present
        if (routeLayerRef.current) {
          try {
            mapInstanceRef.current.removeLayer(routeLayerRef.current);
          } catch (error) {
            console.error('Error removing route layer:', error);
          }
          routeLayerRef.current = null; // Clear reference
        }

        // Remove existing markers
        mapInstanceRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            mapInstanceRef.current.removeLayer(layer);
          }
        });

        // Add user and destination markers
        L.marker([userCoords.lat, userCoords.lng]).addTo(mapInstanceRef.current)
          .bindPopup('Sua localização')
          .openPopup();

        L.marker([destinationCoords.lat, destinationCoords.lng]).addTo(mapInstanceRef.current)
          .bindPopup('Destino')
          .openPopup();

        // Add routing control without showing the default routing layer
        const routingControl = L.Routing.control({
          waypoints: [
            L.latLng(userCoords.lat, userCoords.lng),
            L.latLng(destinationCoords.lat, destinationCoords.lng)
          ],
          routeWhileDragging: true,
          createMarker: () => null, // Hide markers on the route
          showAlternatives: false,
          addWaypoints: false,
          lineOptions: {
            styles: [{ color: '#6FA1EC', weight: 4 }] // Customize the line style
          },
          show: false, // Hide the routing instructions panel
          autoRoute: true
        }).addTo(mapInstanceRef.current);

        // Handle routes found event
        routingControl.on('routesfound', function(e) {
          if (routeLayerRef.current) {
            try {
              mapInstanceRef.current.removeLayer(routeLayerRef.current);
            } catch (error) {
              console.error('Error removing route layer:', error);
            }
          }
          // Add route line manually to avoid default routing layer
          routeLayerRef.current = L.polyline(e.routes[0].coordinates, { color: '#6FA1EC', weight: 4 }).addTo(mapInstanceRef.current);
        });

        // Handle routing errors
        routingControl.on('routingerror', function(e) {
          console.error('Routing error:', e);
        });

        // Remove alternative routes layer
        mapInstanceRef.current.eachLayer((layer) => {
          if (layer.getContainer && layer.getContainer().classList.contains('leaflet-routing-alt')) {
            mapInstanceRef.current.removeLayer(layer);
          }
        });
      }
    };

    // Initialize or update the map when coordinates change
    initializeMap();

    // Cleanup function to remove map instance and layers on component unmount
    return () => {
      if (mapInstanceRef.current) {
        // Remove route layer
        if (routeLayerRef.current) {
          try {
            mapInstanceRef.current.removeLayer(routeLayerRef.current);
          } catch (error) {
            console.error('Error removing route layer:', error);
          }
          routeLayerRef.current = null; // Clear reference
        }

        // Remove all layers including markers
        mapInstanceRef.current.eachLayer((layer) => {
          if (layer instanceof L.TileLayer || layer instanceof L.Marker || layer instanceof L.Polyline) {
            mapInstanceRef.current.removeLayer(layer);
          }
        });

        // Remove map instance
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null; // Clear reference
      }
    };
  }, [userCoords, destinationCoords]);

  return <div id="map" style={{ height: '100%', width: '100%' }} ref={mapRef}></div>;
};

export default MapWithRoute;
