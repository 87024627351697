// src/App.js
import React, { useState, useEffect } from 'react'; // Importação correta
import { AppBar, Toolbar, Typography, Container, Box, IconButton, Drawer, List, ListItem, ListItemText, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';

// Importação das páginas
import Inicio from './pages/Inicio';
import Busca from './pages/Busca';
import Incluir from './pages/Incluir';
import Conta from './pages/Conta';
import { AuthProvider } from './AuthContext'; // Certifique-se de que o caminho está correto
import ProtectedRoute from './ProtectedRoute'; // Certifique-se de que o caminho está correto

// Substitua 'YOUR_GOOGLE_CLIENT_ID' pelo ID do cliente Google OAuth
const GOOGLE_CLIENT_ID = '335600275467-a4dvotjjp34vh0sbotci5sajo2ed1tp7.apps.googleusercontent.com';

// Criação do tema com fontes personalizadas e ajuste no tamanho da fonte
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Roboto, Arial, sans-serif',
    fontSize: 12,
    h1: {
      fontSize: '2.4rem', // Ajuste o tamanho conforme necessário
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '0.875rem', // Ajuste o tamanho conforme necessário
    },
    body2: {
      fontSize: '0.75rem',
    },
  },
});

// Componente para gerenciar a navegação e estado das abas
function NavigationProvider() {
  const location = useLocation();
  const [value, setValue] = useState('inicio');

  useEffect(() => {
    // Atualiza a aba selecionada com base na URL
    const path = location.pathname;
    let newValue;

    switch (path) {
      case '/busca':
        newValue = 'busca';
        break;
      case '/incluir':
        newValue = 'incluir';
        break;
      case '/conta':
        newValue = 'conta';
        break;
      default:
        newValue = 'inicio';
    }

    setValue(newValue);
    localStorage.setItem('selectedTab', newValue);
  }, [location.pathname]);

  useEffect(() => {
    const storedValue = localStorage.getItem('selectedTab');
    if (storedValue) {
      setValue(storedValue);
    }
  }, []);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue);
      }}
      showLabels
      style={{ position: 'fixed', bottom: 0, width: '100%' }}
    >
      <BottomNavigationAction
        label="Início"
        value="inicio"
        icon={<HomeIcon sx={{ color: 'blue' }} />} // Define a cor do ícone
        component={Link}
        to="/"
        sx={{
          color: value === 'inicio' ? '#000' : '#444', // Texto escuro quando ativo, cinza escuro quando inativo
          backgroundColor: value === 'inicio' ? '#e3f2fd' : '#f5f5f5', // Azul pastel quando ativo, cinza muito claro quando inativo
          boxShadow: value === 'inicio' ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)', // Sombra mais pronunciada quando ativo
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: '#bbdefb', // Azul pastel mais forte ao passar o mouse
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Sombra mais forte ao passar o mouse
          }
        }}
      />
      <BottomNavigationAction
        label="Busca"
        value="busca"
        icon={<SearchIcon sx={{ color: 'orangered' }}/>}
        component={Link}
        to="/busca"
        sx={{
          color: value === 'busca' ? '#000' : '#444', // Texto escuro quando ativo, cinza escuro quando inativo
          backgroundColor: value === 'busca' ? '#fff9c4' : '#f5f5f5', // Amarelo pastel quando ativo, cinza muito claro quando inativo
          boxShadow: value === 'busca' ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)', // Sombra mais pronunciada quando ativo
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: '#fff59d', // Amarelo pastel mais forte ao passar o mouse
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Sombra mais forte ao passar o mouse
          }
        }}
      />
      <BottomNavigationAction
        label="Incluir"
        value="incluir"
        icon={<AddBoxIcon sx={{ color: 'green' }}/>}
        component={Link}
        to="/incluir"
        sx={{
          color: value === 'incluir' ? '#000' : '#444', // Texto escuro quando ativo, cinza escuro quando inativo
          backgroundColor: value === 'incluir' ? '#d9ffcc' : '#f5f5f5', // Verde pastel quando ativo, cinza muito claro quando inativo
          boxShadow: value === 'incluir' ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)', // Sombra mais pronunciada quando ativo
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: '#a5d6a7', // Verde pastel mais forte ao passar o mouse
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Sombra mais forte ao passar o mouse
          }
        }}
      />
      <BottomNavigationAction
        label="Conta"
        value="conta"
        icon={<AccountCircleIcon sx={{ color: 'magenta' }}/>}
        component={Link}
        to="/conta"
        sx={{
          color: value === 'conta' ? '#000' : '#444', // Texto escuro quando ativo, cinza escuro quando inativo
          backgroundColor: value === 'conta' ? '#f8bbd0' : '#f5f5f5', // Rosa pastel quando ativo, cinza muito claro quando inativo
          boxShadow: value === 'conta' ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)', // Sombra mais pronunciada quando ativo
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: '#f48fb1', // Rosa pastel mais forte ao passar o mouse
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Sombra mais forte ao passar o mouse
          }
        }}
      />
    </BottomNavigation>
  );
}

function App() {
  const [open, setOpen] = useState(false); // State to control menu open/close

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <div className="App">
              <AppBar position="static" style={{backgroundColor: 'gold', color: 'orangered'}}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(true)}>
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Arraial Ofertas
                  </Typography>
                </Toolbar>
              </AppBar>

              <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
                <Box role="presentation" onClick={() => setOpen(false)} onKeyDown={() => setOpen(false)}>
                  <List>
                    {['Profile', 'Settings', 'Logout'].map((text, index) => (
                      <ListItem button key={text}>
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Drawer>

              <Container>
                <Routes>
                  <Route path="/" element={<Inicio />} />
                  <Route path="/busca" element={<Busca />} />
                  <Route path="/incluir" element={<ProtectedRoute element={<Incluir />} />} />
                  <Route path="/conta" element={<Conta />} />
                </Routes>
              </Container>

              {/* Adiciona o NavigationProvider abaixo de Routes */}
              <NavigationProvider />
            </div>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
