import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Box, IconButton, Modal, Paper, Fade, Zoom } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
import SearchBar from './SearchBar';
import { haversineDistance } from '../utils';
import productsData from '../data/products.json';

function Busca() {
  const [results, setResults] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [products, setProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const coords = '-16.489812,-39.085891';

  useEffect(() => {
    const calculateDistances = () => {
      const updatedProducts = productsData.map(product => {
        const updatedItemList = product.itemList.map(item => {
          const distance = haversineDistance(coords, item.coords);
          return { ...item, distance: `${distance} km` };
        });

        return { ...product, itemList: updatedItemList };
      });

      setProducts(updatedProducts);
      setResults(updatedProducts);
    };

    calculateDistances();
  }, []);

  const handleSearch = (filteredResults) => {
    setResults(filteredResults);
  };

  const handleImageClick = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  const sortItemList = (itemList) => {
    if (!sortBy) {
      return itemList;
    }

    return itemList.sort((a, b) => {
      if (sortBy === 'price') {
        return a.price - b.price;
      } else if (sortBy === 'distance') {
        const distanceA = parseFloat(a.distance.split(' ')[0]);
        const distanceB = parseFloat(b.distance.split(' ')[0]);
        return distanceA - distanceB;
      } else if (sortBy === 'location') {
        return a.location.localeCompare(b.location);
      }
      return 0;
    });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px', textAlign: 'center', fontFamily: 'Poppins, sans-serif' }}>
        Explore em Arraial D'Ajuda
      </Typography>
      <Box mt={2}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <IconButton color={sortBy === 'price' ? 'primary' : 'default'} onClick={() => setSortBy('price')}>
              <AttachMoneyIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton color={sortBy === 'distance' ? 'primary' : 'default'} onClick={() => setSortBy('distance')}>
              <LocationOnIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton color={sortBy === 'location' ? 'primary' : 'default'} onClick={() => setSortBy('location')}>
              <SortIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <SearchBar onSearch={handleSearch} products={products} />
      <Box mt={2} style={{ paddingBottom: '56px' }}>
        <Grid container spacing={3}>
          {results.length > 0 ? (
            results.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card className="product-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <CardMedia
                    component="img"
                    image={product.thumb}
                    alt={product.categoryName}
                    style={{
                      height: '120px',
                      width: '100%',
                      objectFit: 'contain',
                      marginBottom: '8px'
                    }}
                    onClick={() => handleImageClick(product)}
                  />
                  <CardContent style={{ width: '100%' }}>
                    <Typography gutterBottom variant="h6" component="div" style={{ fontSize: '1rem', textAlign: 'center' }}>
                      {product.categoryName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px', fontFamily: 'Verdana', textAlign: 'center' }}>
                      {product.description}
                    </Typography>
                    <Box mt={2}>
                      {sortItemList(product.itemList).map((item, index) => (
                        <Typography key={index} style={{ fontSize: '11px', textAlign: 'center' }}>
                          <strong>{item.location}</strong>: {item.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {item.distance}
                        </Typography>
                      ))}
                    </Box>
                    <Typography variant="body2" color="text.secondary" style={{ fontSize: '11px', textAlign: 'center', fontFamily: 'Verdana'}}>
                      <strong>Peso:</strong> {product.weight}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ fontSize: '11px', textAlign: 'center', fontFamily: 'Verdana' }}>
                      <strong>Marca:</strong> {product.brand}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ fontSize: '11px', textAlign: 'center', fontFamily: 'Verdana' }}>
                      <strong>Código:</strong> {product.codProduto}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" component="div" style={{ textAlign: 'center', width: '100%' }}>
              Nenhum produto encontrado.
            </Typography>
          )}
        </Grid>
      </Box>
      <Modal open={modalOpen} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={modalOpen}>
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: 'blur(5px)' }}>
            <Zoom in={modalOpen}>
              <Paper style={{ width: '90%', maxWidth: '600px', margin: 'auto', marginTop: '10%', padding: '20px', position: 'relative', backgroundColor: 'white', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
                {selectedProduct && (
                  <div>
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: '#000',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '8px',
                        borderRadius: '50%',
                      }}
                      onClick={handleCloseModal}
                    >
                      <CloseIcon />
                    </IconButton>
                    <CardMedia
                      component="img"
                      image={selectedProduct.thumb}
                      alt={selectedProduct.categoryName}
                      style={{
                        height: '200px',
                        objectFit: 'contain',
                        marginBottom: '20px'
                      }}
                    />
                    <Typography variant="h5" style={{ fontSize: '1.25rem', textAlign: 'center' }}>{selectedProduct.categoryName}</Typography>
                    <Typography variant="body1" style={{ fontSize: '12px', textAlign: 'center' }}>{selectedProduct.description}</Typography>
                    <Typography variant="body1" style={{ marginTop: '8px', fontSize: '12px', textAlign: 'center' }}>
                      <strong>Peso:</strong> {selectedProduct.weight}
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: '8px', fontSize: '12px', textAlign: 'center' }}>
                      <strong>Marca:</strong> {selectedProduct.brand}
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: '8px', fontSize: '12px', textAlign: 'center' }}>
                      <strong>Código:</strong> {selectedProduct.codProduto}
                    </Typography>
                    <Box mt={2}>
                      {selectedProduct.itemList.map((item, index) => (
                        <Typography key={index} style={{ fontSize: '12px', textAlign: 'center' }}>
                          <strong>{item.location}</strong>: {item.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {item.distance}
                        </Typography>
                      ))}
                    </Box>
                  </div>
                )}
              </Paper>
            </Zoom>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Busca;
