import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia, Modal, Paper, Fade, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Importar o ícone de fechar
import { haversineDistance } from '../utils';
import pontosComerciais from '../data/pontos-comerciais.json'; // Atualize o caminho conforme necessário
import MapWithRoute from './MapWithRoute'; // Certifique-se de que o caminho está correto

function Inicio() {
  const [nearestLocations, setNearestLocations] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const userCoords = { lat: -16.489812, lng: -39.085891 }; // Exemplo de coordenadas do usuário

  useEffect(() => {
    const calculateNearestLocations = () => {
      // Usar um conjunto para garantir que não haja duplicatas
      const uniqueItems = new Map();

      pontosComerciais.forEach(location => {
        const coords = location.coords.split(','); // Ajustado para dividir as coordenadas
        if (!uniqueItems.has(location.id)) {
          uniqueItems.set(location.id, {
            ...location,
            distance: parseFloat(haversineDistance(`${userCoords.lat},${userCoords.lng}`, location.coords))
          });
        }
      });

      // Ordena os itens por distância
      const itemsWithDistance = Array.from(uniqueItems.values()).sort((a, b) => a.distance - b.distance);

      // Obtém os 5 pontos mais próximos (ou o número que você preferir)
      const nearestItems = itemsWithDistance.slice(0, 5);

      // Agrupa os itens por tipo e os ordena conforme necessário
      const groupedItems = nearestItems.reduce((acc, item) => {
        if (!acc[item.tipo]) {
          acc[item.tipo] = [];
        }
        acc[item.tipo].push(item);
        return acc;
      }, {});

      // Ordena os tipos e agrupa os itens
      const orderedItems = {
        "Supermercado": groupedItems["Supermercado"] || [],
        "Farmácia": groupedItems["Farmácia"] || []
      };

      console.log('Ordered Items:', orderedItems); // Linha de depuração
      setNearestLocations(orderedItems);
    };

    calculateNearestLocations();
  }, []);

  const handleImageClick = (location) => {
    setSelectedLocation(location);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedLocation(null);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px', textAlign: 'center', fontFamily: 'Poppins, sans-serif' }}>
        Pontos Comerciais Próximos
      </Typography>
      <Box mt={3} style={{ paddingBottom: '56px' }}>
        <Grid container spacing={3}>
          {Object.keys(nearestLocations).length > 0 ? (
            Object.keys(nearestLocations).map((tipo) => (
              <div key={tipo} style={{ width: '100%' }}>
                <Typography variant="h5" style={{ marginTop: '20px', textAlign: 'center', fontFamily: 'Poppins, sans-serif' }}>
                  {tipo}s
                </Typography>
                <Grid container spacing={3}>
                  {Array.isArray(nearestLocations[tipo]) ? (
                    nearestLocations[tipo].map((item) => (
                      <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <Card className="product-card" style={{ height: '100%', maxWidth: '300px', margin: '0 auto' }}>
                          <CardMedia
                            component="img"
                            image={`/imagens/${item.thumb}`} // Atualize o caminho se necessário
                            alt={item.name}
                            style={{
                              height: '200px', // Defina a altura desejada
                              width: '100%', // Largura 100% do container
                              objectFit: 'cover', // Ajusta a imagem para cobrir o container sem distorcer
                            }}
                            onClick={() => handleImageClick(item)} // Adiciona a função de clique
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                              {item.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.description}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.distance.toFixed(1)} km
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h6" component="div" style={{ textAlign: 'center', width: '100%' }}>
                      Nenhum ponto comercial encontrado para {tipo}.
                    </Typography>
                  )}
                </Grid>
              </div>
            ))
          ) : (
            <Typography variant="h6" component="div" style={{ textAlign: 'center', width: '100%' }}>
              Nenhum ponto comercial encontrado.
            </Typography>
          )}
        </Grid>
      </Box>
      <Modal open={modalOpen} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={modalOpen}>
          <Paper style={{ width: '90%', maxWidth: '600px', margin: 'auto', marginTop: '10%', padding: '20px', position: 'relative', backgroundColor: 'white' }}>
            {selectedLocation && (
              <div>
                <IconButton
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: '#000',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Fundo branco semi-transparente
                    padding: '8px',
                    borderRadius: '50%', // Tornar o fundo circular
                  }}
                  onClick={handleCloseModal}
                >
                  <CloseIcon />
                </IconButton>
                <CardMedia
                  component="img"
                  image={`/imagens/${selectedLocation.thumb}`} // Atualize o caminho se necessário
                  alt={selectedLocation.name}
                  style={{
                    height: '200px',
                    objectFit: 'cover',
                    marginBottom: '20px'
                  }}
                />
                <Typography variant="h5" style={{ fontSize: '1.25rem' }}>{selectedLocation.name}</Typography>
                <Typography variant="body1" style={{ fontSize: '0.875rem' }}>{selectedLocation.description}</Typography>
                <Typography variant="body1" style={{ marginTop: '8px', fontSize: '0.875rem' }}>
                  <strong>Distância:</strong> {selectedLocation.distance.toFixed(1)} km
                </Typography>
                <div style={{ height: '400px', width: '100%', marginTop: '20px' }}>
                  <MapWithRoute
                    userCoords={userCoords}
                    destinationCoords={{
                      lat: parseFloat(selectedLocation.coords.split(',')[0]),
                      lng: parseFloat(selectedLocation.coords.split(',')[1])
                    }}
                  />
                </div>
              </div>
            )}
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}

export default Inicio;
