// utils.js
export function haversineDistance(coords1, coords2) {
  if (typeof coords1 === 'string') {
    coords1 = coords1.split(',').map(Number);
  }
  if (typeof coords2 === 'string') {
    coords2 = coords2.split(',').map(Number);
  }

  const [lat1, lng1] = coords1;
  const [lat2, lng2] = coords2;

  const R = 6371; // Raio da Terra em km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLng = (lng2 - lng1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLng / 2) * Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c).toFixed(1); // Distance in km
}
