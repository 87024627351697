// src/pages/Incluir.js
import React from 'react';
import { Typography } from '@mui/material';

function Incluir() {
  return (
    <div>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px', textAlign: 'center' }}>
        Incluir Produto
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginTop: '20px', textAlign: 'center' }}>
        Página em construção
      </Typography>
    </div>
  );
}

export default Incluir;
