import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Grid } from '@mui/material';

function SearchBar({ onSearch, products }) {
  const [query, setQuery] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [filteredBrandOptions, setFilteredBrandOptions] = useState([]);
  const [categoryQuery, setCategoryQuery] = useState(null);
  const [brandQuery, setBrandQuery] = useState(null);

  useEffect(() => {
    if (Array.isArray(products)) {
      const categories = [...new Set(products.map(product => product.categoryName))];
      setCategoryOptions(categories);
    } else {
      console.error('Expected `products` to be an array but got:', products);
      setCategoryOptions([]);
      setFilteredBrandOptions([]);
    }
  }, [products]);

  useEffect(() => {
    if (categoryQuery && Array.isArray(products)) {
      const brands = [...new Set(products
        .filter(product => product.categoryName === categoryQuery)
        .map(product => product.brand))];
      setFilteredBrandOptions(brands);
    } else {
      setFilteredBrandOptions([]);
    }
  }, [categoryQuery, products]);

  const handleQueryChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    const filtered = filterProducts(products, newQuery, categoryQuery, brandQuery);
    onSearch(filtered);
  };

  const handleCategoryChange = (event, value) => {
    setCategoryQuery(value);
    setBrandQuery(null); // Reset brand selection when category changes
    const filtered = filterProducts(products, query, value, null);
    onSearch(filtered);
  };

  const handleBrandChange = (event, value) => {
    setBrandQuery(value);
    const filtered = filterProducts(products, query, categoryQuery, value);
    onSearch(filtered);
  };

  const filterProducts = (products, query, category, brand) => {
    if (!Array.isArray(products)) {
      console.error('Expected `products` to be an array but got:', products);
      return [];
    }
  
    let filtered = products;
  
    if (category) {
      filtered = filtered.filter(product => product.categoryName === category);
    }
  
    if (brand) {
      filtered = filtered.filter(product => product.brand === brand);
    }
  
    if (query) {
      const queryLower = query.toLowerCase();
      filtered = filtered.filter(product => {
        const categoryName = product.categoryName ? product.categoryName.toLowerCase() : '';
        const brand = product.brand ? product.brand.toLowerCase() : '';
        const description = product.description ? product.description.toLowerCase() : '';
  
        return categoryName.includes(queryLower) ||
               brand.includes(queryLower) ||
               description.includes(queryLower) ||
               (product.itemList && product.itemList.some(item => {
                 const itemLocation = item.location ? item.location.toLowerCase() : '';
                 const itemDescription = item.description ? item.description.toLowerCase() : '';
                 return itemLocation.includes(queryLower) || itemDescription.includes(queryLower);
               }));
      });
    }
  
    return filtered;
  };

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ marginTop: '10px' }}>
      <Grid item>
        <TextField
          label="Buscar produtos"
          value={query}
          onChange={handleQueryChange}
          variant="outlined"
          size="small"
          style={{ width: '220px', fontSize: '0.75rem' }}
          InputProps={{ style: { fontSize: '0.75rem' } }}
          InputLabelProps={{ style: { fontSize: '0.75rem' } }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          options={categoryOptions}
          value={categoryQuery}
          onChange={handleCategoryChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Categoria"
              variant="outlined"
              size="small"
              style={{ width: '220px', fontSize: '0.75rem' }}
              InputProps={{ ...params.InputProps, style: { fontSize: '0.75rem' } }}
              InputLabelProps={{ style: { fontSize: '0.75rem' } }}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          options={filteredBrandOptions}
          value={brandQuery}
          onChange={handleBrandChange}
          noOptionsText={categoryQuery ? 'Nenhuma opção' : 'Selecione a Categoria'}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Marca"
              variant="outlined"
              size="small"
              style={{ width: '220px', fontSize: '0.75rem' }}
              InputProps={{ ...params.InputProps, style: { fontSize: '0.75rem' } }}
              InputLabelProps={{ style: { fontSize: '0.75rem' } }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default SearchBar;
