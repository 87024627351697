// src/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Certifique-se de que o caminho está correto

function ProtectedRoute({ element }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const redirectTo = location.pathname;

  if (isAuthenticated) {
    return element;
  }

  // Armazena o caminho de destino apenas se estiver tentando acessar "Incluir"
  if (redirectTo === '/incluir') {
    localStorage.setItem('redirectAfterLogin', redirectTo);
  }

  return <Navigate to="/conta" />;
}

export default ProtectedRoute;
